import React, { useEffect, useState, useRef } from "react";
import uploadImg from "assets/upload.png";
import Modal from "react-modal";
import { Formik, Form } from "formik";
import Input from "../Input";
import { BigTitle } from "../../features/NetworkingOnboarding/components";
import CheckboxGroup from "../ChooseGroup";
import { useRecoilState } from "recoil";
import dictsState from "../../recoil/dicts/dictsAtom";
import userAtom from "../../recoil/user/userAtom";
import Button from "../Button";
import { patchUser } from "../../services/users";
import makeRequest from "utils/makeRequest";

const Wrapper = ({ children }: any) => (
	// fixed
	<div className="top-0 w-full flex justify-between p-3 text-black bg-slate-200 items-center z-100">
		{children}
	</div>
);

type LogoType = { logoUrl: string; spaceName: string; spacePage: string };
const Logo = ({ logoUrl, spaceName, spacePage }: LogoType) => (
	<div>
		{logoUrl ? (
			<img src={logoUrl} alt="" />
		) : (
			<div>
				<span className="">{spaceName}</span>
				{"."}
				<span className="">{spacePage}</span>
			</div>
		)}
	</div>
);

const getChooseData = (item: any) => {
	const data = item?.map((item: any) => ({
		content: item.name,
		value: item.id,
	}));
	// console.log("getChooseData", data);
	return data;
};

const MainHeader = () => {
	const [user, setUser] = useRecoilState(userAtom);
	const [dicts, setDicts] = useRecoilState(dictsState);
	const { skills, areas, token } = dicts;
	const [modalIsOpen, setIsOpen] = React.useState(false);

	const [currentFile, setCurrentFile] = useState<File>();
	const [photo, setPhoto] = useState("");
	// const [field, meta, helpers] = useField("photoUrl");
	const fileInput = useRef<HTMLInputElement>(null);
	const selectFile = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// fileInput?.current?.click();
		fileInput.current?.click();
		event.preventDefault();
	}
	const uploadAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		const selectedFiles = files as FileList;
		const file = selectedFiles?.[0];
		setCurrentFile(file);
		const tguser = window.Telegram?.WebApp?.initDataUnsafe.user;
		const form = new FormData();
		form.append('file', file);
		form.append('chat_id', tguser?.id);
		form.append('hash', window.Telegram?.WebApp?.initDataUnsafe.hash);
		event.preventDefault();
		makeRequest
			.post(
				'uploadAvatar',
				form, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((result) => {
				console.debug(result);
				if (result.data.success === true) {
					console.debug(result.data.url);
					const rand = 1 + Math.random() * (9999 - 1);
					result.data.url = result.data.url + "?" + rand;
					setPhoto(result.data.url);
					setUser({
						...user,
						fields: {
							...user.fields,
							telegram_nickname: tguser?.username,
							Avatar: result.data.url
						}
					});
					console.debug("UploadAvatar", user);
					// helpers.setValue(result.data.url);
				}
			}).catch((e) => {
				console.debug(e);
			});
	};

	return (
		<Wrapper>
			<Logo logoUrl={""} spaceName={"Hegai"} spacePage={"Hub"} />
			<button className="font-normal" onClick={() => setIsOpen(true)}>
				{user.fields.name} {user.fields.lastName}
			</button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {
					setIsOpen(false);
				}}
				contentLabel="Example Modal"
			>
				<Formik
					onSubmit={(values) => {
						patchUser(user.fields.id, values);
					}}
					initialValues={{
						name: user.fields.name,
						lastName: user.fields.lastName,
						telegram_nickname: user.fields.telegram_nickname,
						about: user.fields.about,
						areas: user.fields.areas,
						skills: user.fields.skills,
						Avatar: user.fields.Avatar,
						// occupation: user.fields.occupation,
						position: user.fields.position,
						calendly: user.fields.calendly
					}}
				>
					<Form>
						<Input name={"name"} label={"Имя"} />
						<Input
							name={"lastName"}
							label={"Фамилия"}
							defaultValue={user.fields.lastName}
							placeholder={user.fields.lastName}
						/>
						{/* <Input
              name={"telegram_nickname"}
              label={"Ник в телеграме"}
              value={user.fields.telegram_nickname}
              placeholder={user.fields.telegram_nickname}
              readonly={true}
            /> */}
						<Input
							name={"position"}
							label={"Должность"}
							defaultValue={user.fields.position}
							placeholder={user.fields.position}
						/>
						<Input
							name={"calendly"}
							label={"Ссылка на Calendly"}
							defaultValue={user.fields.calendly}
							placeholder={user.fields.calendly}
						/>
						<Input
							name="about"
							textarea
							placeholder={
								"Привет! Меня зовут Паша, я основатель сообщества hegai, со основатель студии виртуальной реальности LikeVR и стартапа Romantic AI\n"
							}
							label="О себе"
							className="col-span-12"
							defaultValue={user.fields.about}
						/>
						<CheckboxGroup
							className="col-span-12"
							groupName={"skills"}
							label={<BigTitle>Навыки</BigTitle>}
							options={getChooseData(skills)}
							maxItems={8}
						/>
						<CheckboxGroup
							className="col-span-12"
							groupName={"areas"}
							label={<BigTitle>Области</BigTitle>}
							options={getChooseData(areas)}
							maxItems={8}
						/>
						{/* <CheckboxGroup
              className="col-span-12"
              groupName={"occupation"}
              label={<BigTitle>Должность</BigTitle>}
              options={getChooseData(occupation)}
              maxItems={8}
            /> */}
						<input id="upload-avatar" type="file" onChange={uploadAvatar} style={{ "display": "none" }} ref={fileInput} />
						{!user?.fields?.Avatar && (
							<div>
								<button
									type={"button"}
									onClick={selectFile}
									className="w-[100px] p-5 mt-5 text-md bg-neutral-100 border-[1px] rounded-lg border-slate-200 flex flex-col items-center"
								>
									<img src={uploadImg} alt="" className={"w-[30px] h-[30px] mb-2"} />{" "}
									<span>Фото</span>
								</button>

							</div>
						)}
						{user?.fields?.Avatar && (
							<button
								className={"col-span-12"}
								onClick={selectFile}
							>
								<img
									src={user?.fields?.Avatar}
									className={"w-[150px] rounded-lg border-[1px] border-slate-200"}
									alt=""
								/>
							</button>

						)}
						<Button type="submit">Сохранить</Button>
					</Form>
				</Formik>
			</Modal>
		</Wrapper>
	);
};

export default MainHeader;
