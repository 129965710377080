import React, { useEffect, useState } from "react";
import { dummyUrl } from "config/consts";
import {
	Header,
	HeaderInfo,
	AvatarImg,
	AvatarWrapper,
	Name,
	Tags,
	TagItem,
	OccupationItem,
	Occupation,
	Description,
	Container,
} from "./styled";
import {
	getRandomUsers,
} from "services/users";
import { getRandomUsersExclusion } from "services/users";
import cross from "features/Networking/assets/cross.svg";
import like from "features/Networking/assets/like.svg";
import matchesSerivce from "../../services/matches";
import userOtherAtom from "recoil/user/userOtherAtom";
import { toast } from "react-toastify";
import { unwrapAirtable } from "../../utils/unwrap";
import { useSwipeable } from "react-swipeable";
import { track } from "@amplitude/analytics-browser";
import { useRecoilState } from "recoil";
import EmptyState from "features/Networking/components/Matching/EmptyState";
import LoadingCard from "features/Networking/components/Matching/components/LoadingCard";
import { matchingCardsAtom } from "features/Networking/components/Matching/recoil/cards";
// import { useLoadBatch } from "features/Networking/components/Matching/recoil/matchingCardsActions";
// import { useRecoilValue } from "recoil";
// import userAtom from "features/Networking/components/Matching/recoil/matchingAtom";
// import userAtom from "recoil/user/userAtom";
// import makeRequest from "utils/makeRequest";
// import { useNavigate } from "react-router-dom";

const buttonClass =
	"drop-shadow-xl active:drop-shadow-sm active:scale-90 transition-all duration-150  mx-5 mb-2 p-2 text-white text-lg uppercase font-medium mg-2 rounded-full flex items-center";

const UserCard = ({ data = {}, next }: any) => {
	const [user, setUser] = useRecoilState(userOtherAtom);
	const rawData = { ...data };
	const currentUser = data.currentUser.fields.telegram_nickname;
	const currentUserId = data.currentUser.fields.chat_Id;
	const [cards, setCardsState]: any = useRecoilState(matchingCardsAtom);

	const likeUser = (userId: any) => {
		const newState = {
			...user,
			loading: true
		};
		setUser(newState);
		console.debug('likeUser');
		matchesSerivce.createAction(currentUser, user?.fields?.telegram_nickname, "like");
		matchesSerivce
			.checkIfMatch(user?.fields?.telegram_nickname, currentUser)
			.then((result) => {
				if (unwrapAirtable(result)[0]) {
					toast("Мэтч!", { autoClose: 1000 });
				}
			});
		getRandomUsersExclusion(data.currentUser.fields.id, user.fields.id).then((normalizedUsers) => {
			console.debug("normalizedUsers", normalizedUsers[0]);
			console.debug("likes", (cards.likes));
			console.debug("slidesIndex", (cards.slidesIndex + 1));
			const newState = {
				...user,
				fields: normalizedUsers[0],
				loading: false
			};
			setUser(newState);
		});
	};

	const skipUser = (userId: any) => {
		setTimeout(() => {
			next();
		}, 100);
		matchesSerivce.createAction(currentUser, user?.fields?.telegram_nickname, "dislike");
		next();
	};

	const handlers = useSwipeable({
		onSwipedLeft: (eventData) => {
			track("swipeLeft");
			skipUser(user?.fields.id);
		},
		onSwipedRight: (eventData) => {
			track("swipeRight");
			likeUser(user?.fields.id);
		},
	});

	useEffect(() => {
		const newState = {
			...user,
			loading: false,
			fields: data.user
		};
		setUser(newState);
	}, []);

	if (user?.fields?.id === undefined) {
		return <EmptyState openDialogs={1} />;
	}

	if (user?.loading !== undefined && user?.loading === true) {
		return <LoadingCard />;
	}

	return (
		<Container {...handlers}>
			<Header>
				<AvatarWrapper>
					<AvatarImg src={user && user?.fields?.Avatar != undefined && user?.fields?.Avatar.length > 0 ? user?.fields?.Avatar : dummyUrl} alt="" />
				</AvatarWrapper>

				<HeaderInfo>
					<Name>
						{user?.fields?.name} {user?.fields?.lastName}
					</Name>
					<Occupation>
						<OccupationItem>{user?.fields?.position ? user?.fields?.position : 'Должность не указана'}</OccupationItem>
						{/* {!occupationsValues?.length && (
              <OccupationItem>🥸 Должность неизвестна</OccupationItem>
            )}
            {occupationsValues.length > 0 && (
              <OccupationItem>{occupationsValues[0]}</OccupationItem>
            )} */}
					</Occupation>
				</HeaderInfo>
			</Header>
			<Description>
				{user?.fields?.about ? user?.fields?.about : 'Информация о себе не указана.'}
			</Description>
			<Tags>
				{!user?.fields?.skillsValues?.length && !user?.fields?.areasValues?.length && (
					<TagItem className={`bg-${"slate"}-400`}>Без навыков</TagItem>
				)}
				{user?.fields?.skillsValues?.map((item: any) => (
					<TagItem key={item} className={`bg-${"slate"}-400`}>
						{item}
					</TagItem>
				))}
				{user?.fields?.areasValues?.map((item: any) => (
					<TagItem key={item} className={`bg-${"slate"}-400`}>
						{item}
					</TagItem>
				))}
			</Tags>
			<div className="flex w-full z-1 justify-between items-center fixed bottom-0 left-0">
				<div>
					<button
						className={"ml-5 -mb-4 bg-black " + buttonClass}
						onClick={() => {
							track("pushDislike");
							skipUser(user?.fields.id);
						}}
					>
						<img src={cross} alt="" className={"w-10 w-[70px]"} />
					</button>
				</div>
				<div className={"flex flex-col items-center"}>
					{/* <span className={"text-lg font-bold"}>{cards.likes}</span> */}
					<button
						className={"mr-5 bg-[#FF7F0A] flex flex-col" + buttonClass}
						onClick={() => {
							track("pushLike");
							likeUser(user?.fields.id);
						}}
					>
						<img src={like} alt="" className={"w-10  w-[70px]"} />
					</button>
				</div>
			</div>
		</Container>
	);
};

export default UserCard;
