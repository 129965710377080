export const unwrapAirtable = (input: any) =>
  input.data.records.map((item: any) => ({
    id: item.id,
    createdTime: item.createdTime,
    ...item.fields,
  }));

export const unwrapApiResponse = (input: any) => {
  // console.log('unwrapApiResponse', input);
  if(input && input.data && input.data.length > 0) {
    return input.data;
    // return input.data.map((item: any) => ({
    //   ...item,
    // }));
  }
  return [];
}


export const unwrapIdsToNames = (
  ids: string[],
  dict: any[],
  log?: boolean
): string[] => {
  return ids?.map((id) => dict.find((dictItem) => dictItem.id === id)?.name);
};
