import { childFlexScreen } from "config/mixClasses";
import MainTitle from "components/typography";
import Button from "components/Button";
import React, { useEffect } from "react";
import { track } from "@amplitude/analytics-browser";

const Text = ({ children }: any) => {
  return <p className={"text-lg font-normal mb-5"}>{children}</p>;
};
const Title = ({ children }: any) => (
  <MainTitle className={"border-b  mb-10 pb-10"}>{children}</MainTitle>
);

const Hello = ({ next, prev, user }: any) => {
  useEffect(() => {
    track("onboarding-first_slide");
  }, []);
  return (
    <div className={childFlexScreen}>
      <div className="text-center max-w-md grow pt-10">
        <MainTitle>
          {/* Привет {user?.first_name}! Это новый раздел
          <span className="text-orange-500">NeRandomCoffee</span> */}
          Привет!
          {/* <span className="text-orange-500">Hegai Hub</span> - это бизнес тиндер сообщества hegai, чтобы найти людей для общения по твоему запросу :) */}
        </MainTitle>
        <Text className={"text-lg"}>
          {/* Заполни анкету и мы подберем интересных людей для общения по твоему
          запросу */}
          <span className="text-orange-500">Hegai Hub</span> - это <b>бизнес тиндер</b> сообщества hegai, чтобы найти людей для общения по твоему запросу 😊 <br /><br />
          Заполни профиль, ставь лайки другим участникам и общайся с теми, с кем случился метч! 🙌🏻
        </Text>
      </div>
      <Button className="my-10" onClick={next}>
        Начать
      </Button>
    </div>
  );
};

export default Hello;
