import React, { useEffect, useState } from "react";
import Button from "components/Button";
import { Title } from "../components";
import Input from "components/Input";
import ChooseGroup from "components/ChooseGroup";
import makeRequest from "utils/makeRequest";
import { toast } from "react-toastify";
import { getUserByTGNick } from "services/users";
import { track } from "@amplitude/analytics-browser";

// todo move to formik submit level
const LastCall = ({ next, prev, data }: any) => {
	useEffect(() => {
		track("onboarding-overview_slide");
	}, []);

	const idsToObjects = (name: any) =>
		data?.values[name]?.map((item: any) => {
			const curr = data?.dicts[name]?.find(
				(dictItem: any) => dictItem.id === item
			);
			return { content: curr.name, value: curr.id };
		});
	const [isLoading, setLoading] = useState(false);
	const [acceptExternal, setExternal] = useState(true);
	const filteredAreas = idsToObjects("areas");
	const filteredSkills = idsToObjects("skills");
	const filteredOcupations = idsToObjects("occupation");

	const sendData = () => {
		// const wind: any = window;
		// const telegramData = wind.Telegram?.WebApp;
		const tguser = window.Telegram.WebApp?.initDataUnsafe.user;
		const tghash = window.Telegram?.WebApp?.initDataUnsafe.hash;
		setLoading(true);
		// Send new user to API
		getUserByTGNick(tguser.username).then((res) => {
			// const url = data.values.photoUrl;
			// delete data.values.photoUrl;
			data.values.telegram_nickname = tguser?.username;
			data.values.chat_id = tguser?.id;
			console.log("Set data.values username to: ", data.values.telegram_nickname, tguser?.username);
			console.log(data.values);
			console.debug('acceptExternal', acceptExternal);
			makeRequest.post("users", {
				...data.values,
				chat_id: tguser?.id,
				tghash: tghash,
				acceptExternal: acceptExternal
			}).then(() => {
				next();
			})
				.catch((e) => {
					if (e.response!.status === 422) {
						toast.error('Не все поля заполнены');
						window.Telegram.WebApp.showAlert("Не все поля заполнены");
					}
					else {
						// toast.error(e.response.statusText);
						toast.error('Something error');
					}
				})
				.finally(() => {
					setLoading(false);
				});
		});
	};

	return (
		<div className={"flex flex-col grow pt-10"}>
			<Title className={"my-10 text-center text-orange-500"}>
				(4/4) Все верно? 👀
			</Title>
			<div className={"flex flex-col items-center text-left grow"}>
				<div className={"text-left w-full grid grid-cols-12 gap-2 mb-10"}>
					<Input className={"col-span-6"} name={"name"} label={""} value={data.values.name} readonly={true} />
					<Input className={"col-span-6"} name={"lastName"} label={""} value={data.values.lastName} readonly={true} />
					{/* <Input className={"col-span-6"} name={"position"} label={""} /> */}
					{/* <Input className={"col-span-6"} name={"calendly"} label={""} /> */}
					<Input name={"position"} label="Должность" className="col-span-12" readonly={true} />
					<Input name={"calendly"} label="Ссылка на Calendly" className="col-span-12" readonly={true} />
					<Input className={"col-span-12"} name={"about"} label={"О себе"} textarea readonly={true} />
					<Input name="city" label="Город" className="col-span-12" readonly={true} />
				</div>

				{/* <ChooseGroup
            groupName={"occupation"}
            options={filteredOcupations}
            label={"Occupation"}
            className={"col-span-12"}
          /> */}

				{/*<Section>*/}
				{/*  <MainTitle className={"mb-2"}>Hobby</MainTitle>*/}
				{/*  <Items>*/}
				{/*    {data.skills?.map((item: any) => (*/}
				{/*      <Item>{item}</Item>*/}
				{/*    ))}*/}
				{/*  </Items>*/}
				{/*</Section>*/}
			</div>

			<div className={"text-center"}>
				{filteredSkills &&
					<ChooseGroup
						groupName={"skills"}
						options={filteredSkills}
						label={"Skills"}
						className={"col-span-12"}
					/>
				}
				{!filteredSkills && (
					<p>Вы не выбрали ни одного навыка.</p>
				)}
				{filteredAreas &&
					<ChooseGroup
						groupName={"areas"}
						options={filteredAreas}
						label={"Areas"}
						className={"col-span-12"}
					/>
				}
				{!filteredAreas && (
					<p>Вы не выбрали ни одной области интереса.</p>
				)}
			</div>

			<div className={"text-center"}>
				<Button
					loading={isLoading}
					type={"submit"}
					className={"mb-3"}
					onClick={() => {
						sendData();
					}}
				>
					Создать анкету
				</Button>
				<label htmlFor="" className={"block pt-2 pb-5"}>
					<input
						type="checkbox"
						checked={acceptExternal}
						onChange={() => {
							setExternal(!acceptExternal);
						}}
					/>{" "}
					Готов мэтчиться с внешними участниками
				</label>
				<button
					className={"text-sm underline"}
					type="button"
					onClick={() => {
						prev();
					}}
				>
					Исправить
				</button>
			</div>
		</div>
	);
};

export default LastCall;
