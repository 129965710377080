import Input from "components/Input";
// import InputNewField from "components/InputNewField";
import CheckboxGroup from "components/ChooseGroup";
import React, { useEffect, useState, useRef } from "react";
import { Title } from "../components";
import makeRequest from "utils/makeRequest";
import { unwrapApiResponse } from "utils/unwrap";
// import * as filestack from "filestack-js";
import uploadImg from "assets/upload.png";
import { useField } from "formik";
import { track } from "@amplitude/analytics-browser";


const AboutOne = ({ data }: any) => {
	const [user, setUser]: any = useState(null);
	const [currentFile, setCurrentFile] = useState<File>();
	const [photo, setPhoto] = useState("");
	const [field, meta, helpers] = useField("photoUrl");
	const fileInput = useRef<HTMLInputElement>(null);
	const tguser = window.Telegram?.WebApp?.initDataUnsafe.user;
	// const client = filestack.init("AFhZiYsO5RvyyJNHgDIq4z");
	// const options = {
	// 	onFileUploadFinished: (res: any) => {
	// 		setPhoto(res.url);
	// 		helpers.setValue(res.url);
	// 		setUser({
	// 			...user,
	// 			photo_url: res.url
	// 		});
	// 	},
	// };
	// const handleSelect = (e: React.MouseEvent<HTMLElement>) => {
	// 	e.preventDefault();
	// 	if (fileInput) {
	// 		fileInput.current.select();
	// 	}
	// };
	const props = {
		"name": tguser?.username,
		"placeholder": "Телеграм юзернейм",
		"value": tguser?.username,
		"defaultValue": tguser?.username,
		"label": "Телеграм",
		"className": "col-span-12"
	}
	const tgField = Input(props);

	const propsName = {
		"name": "name",
		"placeholder": "Имя",
		"defaultValue": tguser?.first_name,
		// "value": tguser?.first_name,
		"label": "Имя",
		"className": "col-span-6"
	}
	const usernameField = Input(propsName);

	const propsLastName = {
		"name": "lastName",
		"placeholder": "Фамилия",
		"defaultValue": tguser?.last_name,
		// "value": tguser?.last_name,
		"label": "Фамилия",
		"className": "col-span-6"
	}
	const userlastnameField = Input(propsLastName);

	const selectFile = () => {
		fileInput.current?.click();
	}

	const uploadAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		const selectedFiles = files as FileList;
		const file = selectedFiles?.[0];
		setCurrentFile(file);

		const form = new FormData();
		form.append('file', file);
		form.append('chat_id', tguser?.id);
		form.append('hash', window.Telegram?.WebApp?.initDataUnsafe.hash);
		makeRequest
			.post(
				'uploadAvatar',
				form, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((result) => {
				console.debug(result);
				if (result.data.success === true) {
					console.debug(result.data.url);
					const rand = 1 + Math.random() * (9999 - 1);
					result.data.url = result.data.url + "?" + rand;
					setPhoto(result.data.url);
					setUser({
						...user,
						photo_url: result.data.url,
					});
					helpers.setValue(result.data.url);
				}
			}).catch((e) => {
				console.debug(unwrapApiResponse(e));
			});
	};

	useEffect(() => {
		const wind: any = window;
		const telegramData = wind.Telegram?.WebApp;
		const tguser = telegramData?.initDataUnsafe.user;
		if (telegramData?.expand) {
			telegramData?.expand();
		}
		// console.debug(user?.photo_url);
		setUser({
			username: tguser?.username ? `@${tguser?.username}` : "",
			first_name: tguser?.first_name,
			last_name: tguser?.last_name,
			name: tguser?.first_name,
			lastName: tguser?.last_name,
			chat_id: tguser?.id
			// photo_url: user?.photo_url,
		});
		// usernameField.props.meta.value = "1";
		// Input.setValue(user?.first_name);

		// helpers.setValue(defaultValue);
		// Input.helpers.setValue(defaultValue);

		track("onboarding-first_slide");
	}, []);

	return (
		<div className="pt-10">
			<Title>(1/4) Расскажи о себе ⭐️</Title>
			<div className="grid grid-cols-12 gap-2 mb-2">
				{/* <Input name="name" label="Имя" className="col-span-6" placeholder="Имя" defaultValue={user?.first_name} /> */}
				{usernameField}
				{/* <Input name="lastName" label="Фамилия" className="col-span-6" placeholder="Фамилия" defaultValue={user?.last_name} /> */}
				{userlastnameField}
				{tgField}
				{/* <Input
					name="telegram_nickname"
					placeholder="Телеграм юзернейм"
					value={user?.username}
					label="Телеграм"
					className="col-span-12"
					readonly={true}
				/> */}
				<Input
					name="position"
					placeholder="Название должности"
					label="Ваша должность"
					className="col-span-12"
				/>
				<Input
					name="calendly"
					placeholder="Ссылка на Calendly"
					label="Ссылка на Calendly"
					className="col-span-12"
				/>
				<Input
					name="about"
					textarea
					placeholder={
						"Привет! Меня зовут Паша, я основатель сообщества hegai, со основатель студии виртуальной реальности LikeVR и стартапа Romantic AI\n"
					}
					label="О себе"
					className="col-span-12"
				/>
				<Input name="city" label="Город" className="col-span-8" />
				<CheckboxGroup
					className="col-span-4"
					groupName={"gender"}
					label={"Пол"}
					noVerticalMargins
					radio
					options={[
						{ value: "male", content: "М" },
						{ value: "female", content: "Ж" },
					]}
				/>
				<input id="upload-avatar" type="file" onChange={uploadAvatar} style={{ "display": "none" }} ref={fileInput} />
				{!photo && (
					<div>
						<button
							type={"button"}
							onClick={selectFile}
							className="w-[100px] p-5 mt-5 text-md bg-neutral-100 border-[1px] rounded-lg border-slate-200 flex flex-col items-center"
						>
							<img src={uploadImg} alt="" className={"w-[30px] h-[30px] mb-2"} />{" "}
							<span>Фото</span>
						</button>

					</div>
				)}
				{photo && (
					<button
						className={"col-span-12"}
						onClick={selectFile}
					>
						<img
							src={photo}
							className={"w-[150px] rounded-lg border-[1px] border-slate-200"}
							alt=""
						/>
					</button>

				)}
				{/* {!photo && (
					<button
						type={"button"}
						onClick={() => {
							client.picker(options).open();
						}}
						className="w-[100px] p-5 mt-5 text-md bg-neutral-100 border-[1px] rounded-lg border-slate-200 flex flex-col items-center"
					>
						<img src={uploadImg} alt="" className={"w-[30px] h-[30px] mb-2"} />{" "}
						<span>Фото</span>
					</button>
				)}
				{photo && (
					<button
						className={"col-span-12"}
						onClick={() => {
							client.picker(options).open();
						}}
					>
						<img
							src={photo}
							className={"w-[150px] rounded-lg border-[1px] border-slate-200"}
							alt=""
						/>
					</button>
				)} */}
				<button className={"absolute -left-100"}></button>
			</div>
		</div>
	);
};

export default AboutOne;
