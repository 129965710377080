import { atom } from "recoil";
import { IUser } from "./types";

const userOtherAtom = atom({
  key: "userOtherAtom",
  default: {
    fields: {
      telegram_nickname: "",
      finishedOnboardings: [],
    },
    loading: true,
    fulfilled: false,
  } as IUser,
});

export default userOtherAtom;
