import makeRequest from "../utils/makeRequest";
import { unwrapApiResponse } from "../utils/unwrap";

const dictsService = {
  getAreas: () =>
    makeRequest
      // .get(`areas?&filterByFormula=Search('1', {level})`).then((res) => unwrapApiResponse(res))
      .get(`areas`).then((res) => unwrapApiResponse(res))
  ,
  // getOccupations: () =>
  //   makeRequest.get(`position?offset=0`).then((res) => unwrapApiResponse(res))
  // ,
  getSkills: () =>
    makeRequest
      // .get(`skills?&filterByFormula=Search('1', {level})`).then((res) => unwrapApiResponse(res)).catch( (e) => {
      .get(`skills`).then((res) => unwrapApiResponse(res)).catch( (e) => {
        if(e.response.status == 401) { localStorage.removeItem('token'); location.reload();}
      })
  ,
  getConnections: (tg_nick: string) =>
    makeRequest
      .get(`connections?telegram_nickname=` + tg_nick).then((res) => unwrapApiResponse(res)).catch( (e) => {
        if(e.response.status == 401) { localStorage.removeItem('token'); location.reload();}
      })
  ,
  getToken: () =>
    makeRequest
      .get(`token`).then((res) => res.data.token).catch( (e) => {
        if(e.response.status == 401) { localStorage.removeItem('token'); location.reload();}
      })
};

export default dictsService;
