import { useRecoilState } from "recoil";
import dictsState from "./dictsAtom";
import dictsService from "../../services/dicts";
import { useEffect } from "react";

export const useDictsBootstrap = () => {
  const [dicts, setDicts] = useRecoilState(dictsState);
  const fetchData = async () => {
    const wind: any = window;
    const telegramData = wind.Telegram?.WebApp;
    const user = telegramData?.initDataUnsafe.user;
    const [areas, skills, connections, token]: any = await Promise.all([
      // dictsService.getOccupations(),
      dictsService.getAreas(),
      dictsService.getSkills(),
      dictsService.getConnections(user?.username),
      dictsService.getToken(),
    ]);
    if(!localStorage.getItem('token')) {
      localStorage.setItem('token', token);
      location.reload();
    }
    setDicts({
      loading: false,
      fulfilled: true,
      // occupation,
      areas,
      skills,
      connections,
      token
    });
    
  };

  useEffect(() => {
    if (!dicts.fulfilled) {
      fetchData();
    }

  }, []);

  return dicts;
};
