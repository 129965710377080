import makeRequest from "../utils/makeRequest";
import { unwrapAirtable, unwrapApiResponse } from "../utils/unwrap";


export const getUserByTGNick = (nick: string): Promise<any> =>
	makeRequest
		.get(`users?&telegram_nickname=${nick}`)
		.then((result) => {
			return unwrapApiResponse(result)[0] ? unwrapApiResponse(result)[0] : [];
		}).catch((e) => {
			if (e.response.status == 401) { localStorage.removeItem('token'); location.reload(); }
		});

export const patchUser = async (id: any, fields: any): Promise<any> => {
	const data = await makeRequest.post(`users/` + id, { ...fields });
	location.reload();
	return data;
}

export const getUsersByNick = (nicksArray: string[]): Promise<any> => {
	const formula = nicksArray
		.reduce((acc: any, curr: any) => {
			// return `${acc}{telegram_nickname}='${curr}',`;
			return `${acc}${curr},`;
		}, "")
		.slice(0, -1);
	// return makeRequest.get(`users?&filterByFormula=OR(${formula})`);
	return makeRequest.get(`users?&filterByNick=${formula}`);
};

export const getUsersByNickTest = (
	nicksArray: string[],
	exclude: string[]
): Promise<any> => {
	const excludeFormula = exclude
		.reduce((acc: any, curr: any) => {
			return `${acc}{telegram_nickname}='${curr}',`;
		}, "")
		.slice(0, -1);
	const includeFormula = nicksArray
		.reduce((acc: any, curr: any) => {
			return `${acc}{telegram_nickname}='${curr}',`;
		}, "")
		.slice(0, -1);
	return makeRequest.get(
		// `users?&filterByFormula=AND(OR(${includeFormula}), NOT(OR(${excludeFormula})))`
		`users?&filterByFormula=AND(OR(${includeFormula}), NOT(OR(${excludeFormula})))`
	);
};

export const getRandomUsers = async (user_id: string | undefined): Promise<any> => {
	const users = await makeRequest.get("random/" + user_id);
	return unwrapApiResponse(users);
};

export const getRandomUsersExclusion = async (user_id: string | undefined, exclusion: string | undefined): Promise<any> => {
	const users = await makeRequest.get("random/" + user_id + "?exclusion=" + exclusion);
	return unwrapApiResponse(users);
};

export const getUsersExcludeByNicks = (
	nicksArray: string[],
	currentUser: string,
	max = 100
): Promise<any> => {
	const formula = nicksArray
		.reduce((acc: any, curr: any) => {
			return `${acc}{telegram_nickname}='${curr}',`;
		}, "")
		.slice(0, -1);
	return makeRequest
		.get(
			`users?maxRecords=${max}&filterByFormula=AND(NOT({name} = BLANK()), NOT({telegram_nickname} = '${currentUser}'), NOT(OR(${formula})))`
		)
		// .then((res) => unwrapAirtable(res));
		.then((res) => unwrapApiResponse(res));
};

export const getAllUsers = async (): Promise<any> => {
	const users = await makeRequest.get("Users");
	const users2 = await makeRequest.get(`Users?offset=${users.data.offset}`);
	return [...unwrapAirtable(users), ...unwrapAirtable(users2)];
};

export const getUsers = async (max = 100): Promise<any> => {
	const users = await makeRequest.get(
		`users?maxRecords=${max}&filterByFormula=OR(NOT({name} = BLANK()))`
	);
	return unwrapAirtable(users);
};
