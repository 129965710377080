import React, { useEffect } from "react";
import { Field, useField } from "formik";

// function validateField(value: any) {
//   let error;
//   if (!value) {
//     error = 'Required';
//   }
//   return error;
// }

const Input = ({
	defaultValue,
	name,
	className,
	label,
	textarea,
	placeholder,
	value,
	readonly
}: {
	defaultValue?: string;
	name: string;
	label: any;
	className?: string;
	textarea?: boolean;
	placeholder?: string;
	value?: string;
	readonly?: boolean
}) => {
	const styles = "hover:outline-none focus:outline-none cursor-pointer p-2 m-1 bg-gray-50 rounded-lg border border-gray-200 inline-block box-border";
	const [meta] = useField(name);
	// const [field, meta, helpers] = useField(name);
	// console.debug("meta", name, meta);
	// const setValue = (new_value: any) => {
	// 	helpers.setValue(new_value);
	// };
	return (
		<div className={`flex flex-col ${className}`}>
			<label className={`font-medium text-md pl-3 mb-2`}>{label}</label>
			{textarea ? (
				<Field
					defaultValue={defaultValue || meta?.value || defaultValue}
					as={"textarea"}
					name={name}
					rows={4}
					cols={12}
					className={styles}
					placeholder={placeholder}
					// value={value ? value : undefined}
					value={value || meta?.value || value}
				// validate={validateField}
				/>
			) : (
				<Field
					// defaultValue={defaultValue}
					defaultValue={defaultValue || meta?.value || defaultValue}
					as={"input"}
					type={"text"}
					name={name}
					className={styles}
					placeholder={placeholder}
					// value={value ? value : undefined}
					value={value || meta?.value || value}
				// validate={validateField}
				/>
			)}
		</div>
	);
};

export default Input;
