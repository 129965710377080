import makeRequest from "../utils/makeRequest";
import { unwrapAirtable, unwrapApiResponse } from "../utils/unwrap";

const matchesSerivce = {
  create: (userOne: string, userTwo: string) =>
    // makeRequest.post("Matches", {
    //   records: [{ fields: { userOne, userTwo } }],
    // }),
    makeRequest.post("matches", {
      "action_from": userOne,
      "action_to": userTwo
    }),
  createAction: (
    actionFrom: string,
    actionTo: string,
    type: "like" | "dislike"
  ) =>
  makeRequest.post("action", {
    'action_from': actionFrom,
    'action_to': actionTo,
    'type': type,
  }),
    // makeRequest.post("matchesactions", {
    //   records: [
    //     {
    //       fields: {
    //         actionFrom,
    //         actionTo,
    //         type,
    //       },
    //     },
    //   ],
    // }),
  checkIfMatch: (actionFrom: string, actionTo: string) =>
    makeRequest.get(
      // `matchesactions?&filterByFormula=AND({actionFrom}='${actionFrom}',{actionTo}='${actionTo}',{type}='like')`
      `matchesactions?&action_from=${actionFrom}&action_to=${actionTo}&type=like`
    ),
  getActions: async (accum = [], offset = "") => {
    const actions = await makeRequest.get(`matchesactions?offset=${offset}`);
    const data = unwrapApiResponse(actions);
    console.log('getActions', data);
    return data;
    // const data: any = [...unwrapAirtable(actions), ...accum];
    // if (actions.data.offset) {
    //   const res: any = await matchesSerivce.getActions(
    //     data,
    //     actions.data.offset
    //   );
    //   return res;
    // } else {
    //   return accum;
    // }
  },
  checkForLike: async (lh: string, rh: string, offset = "") => {
    const response = await makeRequest.get(`matchesactions?offset=${offset}`);
    // const data = unwrapAirtable(response);
    const data = unwrapApiResponse(response);
    console.log('checkForLike', data);
    return data;
    // let isLikeFound = false;
    // data.forEach((item: any) => {
    //   const leftAppears = item.likeFrom === lh || item.likeTo === lh;
    //   const rightAppears = item.likeFrom === rh || item.likeTo === rh;
    //   isLikeFound = leftAppears || rightAppears;
    // });
    // if (isLikeFound) {
    //   return true;
    // } else {
    //   const res: any = await matchesSerivce.checkForLike(
    //     lh,
    //     rh,
    //     response.data.offset
    //   );
    //   return res;
    // }
  },
  getMatchesByNickname: (nickname: string) =>
    makeRequest.get(
      // `matches?&filterByFormula=OR({userOne}='${nickname}',{userTwo}='${nickname}')`
      // &action_to=${nickname}
      `matches?&action_from=${nickname}`
    ),

  getActionsToUser: async (
    telegram_nickname: string,
    type?: "like" | "dislike",
    max?: number
  ) => {
    const actions = await makeRequest.get(
      // prettier-ignore
      // `matchesactions?filterByFormula=AND(actionTo='${telegram_nickname}'${type ? `,type = '${type}'` : ""})${max ? `&maxRecords=${max}` : ''}`
      `matchesactions?action_to=${telegram_nickname}&type=${type}&max=${max}`
    );
    // return unwrapAirtable(actions);
    console.log('getActionsToUser', unwrapApiResponse(actions));
    return unwrapApiResponse(actions);
  },

  getActionsByNickname: async (nickname: string) => {
    const actions = await makeRequest.get(
      // `matchesactions?filterByFormula=AND({actionFrom}='${nickname}')`
      `matchesactions?action_from=${nickname}`
    );
    // return unwrapAirtable(actions);
    // return actions;
    console.log('getActionsByNickname', unwrapApiResponse(actions));
    return unwrapApiResponse(actions);
  },

  countTodayLikesFrom: async (nickname: string) => {
    const actions = await makeRequest.get(
      // `matchesactions?filterByFormula=AND({actionFrom}='${nickname}')&view=TodayLikes`
      `matchesactions?action_from=${nickname}&view=TodayLikes`
    );
    // return unwrapAirtable(actions).length;
    console.log('countTodayLikesFrom', actions.data.length);
    return actions.data.length;
  },

  sendNotification: async (userIdOne: number, userIdTwo: number) => {
    const response = await makeRequest.get(
      `matchnotify?userone=${userIdOne}&usertwo=${userIdTwo}`
    );
    return response;
  },
  // sendNotification: async (userIdOne: number, userIdTwo: number) => {
  //   fetch(
  //     `https://hegai-bot.herokuapp.com/matchNotify?userone=${userIdOne}&usertwo=${userIdTwo}`
  //   );
  // },
};
export default matchesSerivce;
